<template>
<div class="photo">
    <div class="image" v-if="photo">
        <img :src="photo.path" />
        <div class="photoInfo">
            <h1 class="photoTitle"><b>{{photo.title}}</b></h1>
            <p class="photoName">{{photo.user.firstName}} {{photo.user.lastName}}</p>
        </div>
        <p class="photoDate">{{formatDate(photo.created)}}</p>
        <br>
        <p>{{photo.description}}</p>
    </div>
    <div v-if="this.$root.$data.user">
        <form class="pure-form" @submit="addComment">
            <legend>Comment</legend>
            <fieldset>
                <textarea v-model="comment" placeholder="Write a comment..."></textarea>
            </fieldset>
            <fieldset class="buttons">
                <button type="submit" class="pure-button pure-button-primary right">Submit</button>
            </fieldset>
        </form>
    </div>
    <hr>
    <div v-for="comment in comments" v-bind:key="comment._id">
        <div class="comment-card">
            <p class="comment-name">{{comment.user.firstName + " " + comment.user.lastName}}</p>
            <p class="comment-comment">{{comment.comment}}</p>
            <p class="photoDate">{{formatDate(comment.created)}}</p>
        </div>
        <hr>
    </div>
</div>
</template> 

<script>
import axios from 'axios';
import moment from 'moment';

export default {
    name: 'Photo',
    data() {
        return {
            photo: null,
            comment: '',
            comments: null
        }
    },
    created() {
        this.getPhoto();
        this.getComments();
    },
     computed: {
        commentz() {
            return this.getComments();
        }
    },
    methods: {
        async getPhoto() {
            try {
                let response = await axios.get(`/api/photos/${this.$route.params.id}`);
                this.photo = response.data;
            } catch (error) {
                // console.log(error);
            }
        },

        formatDate(date) {
            if (moment(date).diff(Date.now(), 'days') < 15) {
                return moment(date).fromNow();
            } else {
                return moment(date).format('d MMMM YYYY');
            }
        },

        async addComment() {
            try {
                await axios.post(`/api/comments/${this.$route.params.id}`, {
                    comment: this.comment,
                    photo: this.$route.params.id,
                    user: this.$root.$data.user
                });
            } catch (error) {
                // console.log(error);
            }

            this.comment = '';
            this.getComments();
        },

        async getComments() {
            try {
                let response = await axios.get(`/api/comments/${this.$route.params.id}`);
                this.comments = response.data;
            } catch (error) {
                // console.log(error);
            }

        }
    }
}
</script>

<style scoped>
.fake-menu {
    padding-top: 10px;
    height: 53.66px;
}

h1 {
    color: black;
}

img {
    width: 25%;
    height: 25%;
}

.photoInfo {
    display: flex;
    justify-content: space-between;
    font-size: 0.8em;
}

.photoInfo p {
    margin: 0px;
}

.photoDate {
    font-size: 0.7em;
    font-weight: normal;
}

p {
    margin: 0px;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

.image-gallery {
    column-gap: 1em;
}

.image {
    margin: 0 0 1.5em;
    display: inline-block;
    width: 100%;
}

.image img {
    width: 100%;
}

.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .3);
    transition: opacity 0.5s ease;
    display: flex;
    transition: background 0.2s ease-in-out, height 1s ease-in-out;
    transition: height 0.2s ease-in-out, width 0.2s ease-in-out;
    justify-content: center;
    transition-timing-function: cubic-bezier(0.64, 0.57, 0.67, 1.53);
}

.modal-container {
    width: 50%;
    height: max-content;
    margin-top: 80px;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all 0.5s ease;
}

.modal-enter {
    opacity: 0;
}

.modal-leave-active {
    opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

form {
    font-size: 11pt;
}

input {
    width: 100%;
}

textarea {
    width: 100%;
    height: 100px;
}

.placeholder {
    background: #F0F0F0;
    width: 200px;
    height: 125px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #333;
    font-size: 14px;
    cursor: pointer;
}

.placeholder:hover {
    background: #E0E0E0;
}

.fileInput {
    display: none;
}

img {
    width: 200px;
}

.buttons {
    display: flex;
    justify-content: space-between;
}

.comment-card {
    margin: 36px 0;

}

.comment-name {
    margin-bottom: 12px;
    font-weight: 400;
}

.comment-comment {
    margin-bottom: 16px;
    padding-left: 12px;
}
</style>